<template>
    <div class="block">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'layout1-block',
    components: {

    },
    data() {
        return {
            
        }
    },
    computed: {
        
    },
    watch: {
       
    }
}
</script>
<style lang="less" scoped>
.block{
    width: 1200px;
}
</style>
