<template>
    <Main>
        <Header></Header>
        <Bg>
            <div class="login-main">
                <div class="form-main">
                    <div class="title">{{$t('login.welcome')}}</div>
                    <el-form ref="form" :model="item" :rules="rules" :show-message="false">
                        <el-form-item prop="mobile">
                            <el-input v-model="item.mobile" :placeholder="fieldText1">
                                <img slot="prefix" class="icon" :src="require('@/assets/img/icon/mobile.png')" />
                            </el-input>
                        </el-form-item>
                        <el-form-item prop="password">
                            <el-input v-model="item.password" show-password :placeholder="fieldText2">
                                <img slot="prefix" class="icon" :src="require('@/assets/img/icon/password.png')" />
                            </el-input>
                        </el-form-item>
                        <el-button type="info" class="login-button" @click="save">{{$t('login.loginButton')}}</el-button>
                        <div class="register-text" v-if="lang == 'zh'">
                            <span class="hint">{{$t('login.noAccount')}}</span>
                            <span class="register" @click="$router.push({path: '/register'})">{{$t('login.register')}}</span>
                        </div>
                        <div class="register-text" v-else-if="lang == 'en'">
                            <span class="register" @click="$router.push({path: '/register'})">{{$t('login.register')}}</span>
                        </div>
                    </el-form>
                </div>
            </div>
        </Bg>
        <Footer></Footer>
    </Main>
</template>

<script>
import { login } from "@/api/user.js";
import Main from '@/components/layout1/layout1-main.vue';
import Header from '@/components/layout1/layout1-header.vue';
import Footer from '@/components/layout1/layout1-footer.vue';
import Bg from '@/components/layout1/layout1-bg.vue';
import {getLanguage} from "@/lang/index.js";
export default {
    name: 'login',
    components: {
        Main,
        Header,
        Footer,
        Bg,
    },
    data() {
        return {
            lang: getLanguage(),
            item: {
                mobile: '',
                password: '',
            },
            rules: {
                mobile: [
                    { required: true, message: this.$t('login.fieldText1Required'), trigger: 'blur' },
                ],
                password: [
                    { required: true, message: this.$t('login.fieldText2Required'), trigger: 'blur' },
                ],
            },
            redirect: ''
        }
    },
    computed: {
        fieldText1() {
            return this.$t('login.fieldText1');
        },
        fieldText2() {
            return this.$t('login.fieldText2');
        }
    },
    created() {
        document.title = this.$t("common.title8");
        if (this.$route.query.redirect) {
            this.redirect = this.$route.query.redirect
        }
    },
    methods: {
        save() {
            this.$refs.form.validate((valid, err) => {
                if (valid) {
                    this.$store.dispatch('user/login', this.item)
                    .then(res => {
                        if (res.code == 0) {
                            this.$router.push({ path: this.redirect || '/' })
                        } else {
                            this.$message.error(res.msg);
                        }
                    })
                } else {
                    for (let key in err) {
                        this.$message.error(err[key][0]['message']);
                        return false;
                    }
                }
            });
        }
    },
    watch: {

    }
}
</script>
<style lang="less" scoped>
.login-main {
    display: flex;
    justify-content: flex-end;
    .icon{
        margin-top:10px;
        width: 20px;
        height: 20px;
        margin-left: 11px;
    }
    /deep/ .el-input__inner{
        padding-left: 46px;
    }
    .form-main {
        margin-top: 80px;
        width: 420px;
        box-sizing: border-box;
        // height: 400px;
        background: #ffffff;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
        border-radius: 4px;
        padding: 60px 30px;
        .title {
            font-size: 20px;
            font-weight: 400;
            color: #333333;
            margin-bottom: 30px;
        }
        /deep/ .el-form-item {
            margin-bottom: 24px;
        }
        /deep/ .el-input {
            .el-input__inner {
                border-radius: 2px;
            }
        }
        .login-button {
            margin-top: 6px;
            background-color: #3053A3;
            border-radius: 2px;
            width: 100%;
            border-color: #0054a5;
        }
        .register-text {
            margin-top: 30px;
            font-size: 14px;
            font-weight: 400;
            text-align: right;
            .hint {
                color: #999999;
            }
            .register {
                color: #3053A3;
                cursor: pointer;
            }
        }
    }
}
</style>
