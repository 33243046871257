<template>
    <Block>
        <div class="header-main">
            <div class="left">
                <img :src="logo" style="cursor:pointer;width: 130px;" @click="$router.push('/')" />
            </div>
            <div class="right">
                <div class="goHome" @click="$router.push('/')">
                    <img :src="require('@/assets/img/home.png')" />
                    {{$t('header.goHome')}}
                </div>
                <language-select></language-select>
            </div>
        </div>
    </Block>
</template>

<script>

import LanguageSelect from "@/components/language-select/index.vue";
import logo from "@/assets/img/logo.png";
import Block from './layout1-block.vue';
export default {
    name: 'layout1-header',
    components: {
        LanguageSelect,
        Block,
    },
    data() {
        return {
            logo,
        }
    },
    computed: {

    },
    watch: {

    }
}
</script>
<style lang="less" scoped>
.header-main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 120px;
    .right {
        display: flex;
        .goHome {
            display: flex;
            align-items: center;
            margin-right: 30px;

            cursor: pointer;

            line-height: 21px;
            font-size: 16px;
            font-weight: 400;
            color: #666666;
            img {
                width: 20px;
                height: 20px;
                margin-right: 6px;
            }
        }
    }
}
</style>
