<template>
    <div class="main">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'layout1-main',
    components: {
       
    },
    data() {
        return {
            
        }
    },
    computed: {
        
    },
    watch: {
       
    }
}
</script>
<style lang="less" scoped>
.main{
    width: 100%;
    min-width: 1280px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
</style>
