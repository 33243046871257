<template>
    <el-select v-model="language" class="language-select">
        <el-option v-for="item in languageList" :key="item.value" :label="item.label" :value="item.value"></el-option>
    </el-select>
</template>

<script>

import { setLanguage,getLanguage } from "@/lang/index.js";
import { languageList } from "./data.js";
export default {
    name: 'language-select',
    components: {

    },
    data() {
        return {
            lang: null,
            languageList: languageList,
        }
    },
    computed: {
        language:{
            set(val){
                setLanguage(val);
                this.lang = getLanguage();
                location.reload();
            },
            get(){
                if(this.lang == null) this.lang = getLanguage();
                return this.lang;
            }
        }
    },
    watch: {

    }
}
</script>
<style lang="less" scoped>
 .language-select{
    width: 140px;
    /deep/ .el-input {
        .el-input__inner{
            padding-left: 44px;
            border-radius: 40px;
            border: 1px solid #CCCCCC;
            background: url('~@/assets/img/language.png') no-repeat;
            background-size: 20px 20px; //图片大小
            background-position: 18px 9px;  //在input中的位置 x y
        }
        .el-input__suffix{
            .el-input__icon{
                font-size: 12px;
                width:30px;
                color: #707070;
            }
        }
    }
}
</style>
