<template>
    <div class="bg">
        <Block>
            <slot></slot>
        </Block>
    </div>
</template>

<script>
import Block from './layout1-block.vue';
export default {
    name: 'layout1-bg',
    components: {
        Block,
    },
    data() {
        return {
            
        }
    },
    computed: {
        
    },
    watch: {
       
    }
}
</script>
<style lang="less" scoped>
.bg{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-width:1200px;
    height: 600px;
    background: url(~@/assets/img/login-bg.png) no-repeat center top #3053A3;
}
</style>
